import React from "react"
import Layout from "components/Layout"

const lost = () => {
  return (
    <div>
      <Layout>
        <div>
          <h1>Not Found </h1>
        </div>
      </Layout>
    </div>
  )
}

export default lost
